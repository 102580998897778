/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

table {
  width: 100%;
}

.user-avatar-menu {
  margin-top: 8px;
}

.new-line {
  white-space: pre-line;
}
